import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { usePageStore } from './page'
import { useConfigStore } from './config'
import { setCookie, getCookie } from './utils/helpers'

const forceTestZoneByCookie = () => {
  return getCookie('forceTestZone') === 'develop'
}

export const useAdaStore = defineStore('ada', () => {
  // Stores
  const pageStore = usePageStore()
  const configStore = useConfigStore()

  // State
  const previewBundle = ref(false)
  const forceTestZone = ref(false)
  const asyncContentMode = ref(false)
  const adSizePrediction = ref({})
  const adSlotMarker = ref({})
  const activatedAdSlotMarker = ref([])

  // Getters
  const blockAds = computed(() => {
    return pageStore.pageData?.metaData?.disallowAds || process.env.AD_FREE_BRANDS.includes(pageStore.brandFromStore)
  })

  const staticMobile1OnHome = computed(() => {
    return pageStore.pageData?.pageMeta?.url === '/'
  })

  const dynamicAdSlotsEnabled = computed(() => {
    return !!configStore.rsConfig?.adSlotUsage?.dynamicAdSlots
  })

  const asideEnabled = computed(() => {
    return !!configStore.rsConfig?.adSlotUsage?.dynamicAdSlots && (pageStore.pageData?.pageMeta?.pageType === 'article' || pageStore.pageData?.metaData?.behaveLikeArticle)
  })

  const loadPreviewBundle = computed(() => {
    return previewBundle.value
  })

  const isForceTestZone = computed(() => {
    return forceTestZone.value
  })

  const isAsyncContentMode = computed(() => {
    return asyncContentMode.value
  })

  const getAdSlotMarker = computed(() => {
    return adSlotMarker.value
  })

  const getActivatedAdSlotMarker = computed(() => {
    return activatedAdSlotMarker.value
  })

  // Actions
  const setPreviewBundle = (newPreviewBundle) => {
    previewBundle.value = newPreviewBundle
  }

  const setForceTestZone = (newEnabled) => {
    forceTestZone.value = newEnabled
  }

  const setAsyncContentMode = (newEnabled) => {
    asyncContentMode.value = newEnabled
  }

  const forcePreviewBundle = () => {
    setPreviewBundle(true)
  }

  const setTestZone = (testZone) => {
    setCookie('forceTestZone', testZone, new Date(Date.now() + 1000 * 60 * 60 * 24))
    setForceTestZone(testZone === 'develop')
  }

  const setAdSizePrediction = (payload) => {
    adSizePrediction.value = payload || {}
  }

  const addAdSlotMarker = (payload) => {
    adSlotMarker.value[payload.offsetTop] = payload
  }

  const setActivatedAdSlotMarker = (payload) => {
    activatedAdSlotMarker.value = payload
  }

  const checkForceTestZoneCookie = () => {
    setForceTestZone(forceTestZoneByCookie())
  }

  return {
    previewBundle,
    forceTestZone,
    asyncContentMode,
    adSizePrediction,
    adSlotMarker,
    activatedAdSlotMarker,
    blockAds,
    staticMobile1OnHome,
    loadPreviewBundle,
    isForceTestZone,
    isAsyncContentMode,
    dynamicAdSlotsEnabled,
    asideEnabled,
    getAdSlotMarker,
    getActivatedAdSlotMarker,
    setPreviewBundle,
    setForceTestZone,
    setAsyncContentMode,
    forcePreviewBundle,
    setTestZone,
    setAdSizePrediction,
    addAdSlotMarker,
    setActivatedAdSlotMarker,
    checkForceTestZoneCookie
  }
})
