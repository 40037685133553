import { mapState } from 'pinia'

import { useConfigStore } from '../stores/config'

export default {
  props: {
    productGroup: {
      type: Object,
      default: () => {}
    },
    brand: {
      type: String,
      default: null
    },
    listOrderPosition: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      outOfStockProductExists: false,
      unifyingSeller: {
        amazon: 'unifyAmazon',
        manual: 'unifyManual',
        awin: 'unifyAwin'
      }
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    labelImageUrl () {
      return this.productGroup?.image && this.productGroup?.image.mimetype
        ? this.createImgSrc({
          image: this.productGroup.image,
          brand: this.brand,
          setName: 'productGroupLabel',
          type: this.productGroup.image.mimetype.split('/')[1],
          srcSetDefinition: {
            format: 'landscape'
          }
        })
        : false
    },
    productGroupStyleClasses () {
      return {
        [`${this.prefix}-content`]: true,
        [`${this.prefix}-content--structure-full`]: true,
        [`${this.prefix}-product-group`]: true,
        [`${this.prefix}-product-group--deals`]: this.productGroup?.productGroupType === 'deals',
        [`${this.prefix}-typo--product-group`]: true
      }
    },
    innerGroupStyleClasses () {
      return {
        [`${this.prefix}-slider`]: this.productGroup?.productGroupType === 'slider' || this.productGroup?.productGroupType === 'deals',
        [`${this.prefix}-slider--product-group`]: this.productGroup?.productGroupType === 'slider' || this.productGroup?.productGroupType === 'deals',
        [`${this.prefix}-product-group--oos-product-exists`]: this.outOfStockProductExists,
        [`${this.prefix}-product-group--deals`]: this.productGroup?.productGroupType === 'deals',
        [`${this.prefix}-product-group--single`]: this.productGroup?.productGroupType === 'single',
        [`${this.prefix}-product-group--has-tech-data`]: this.productGroup?.productGroupType === 'single' && this.hasTechnicalData,
        [`${this.prefix}-product-group__inner`]: true
      }
    },
    badgeStyleClasses () {
      return {
        [`${this.prefix}-badge`]: true,
        [`${this.prefix}-badge--affiliate`]: this.productGroupType === 'single' || this.affiliateType === 'affiliate-link',
        [`${this.prefix}-badge--affiliates`]: this.productGroupType !== 'single'
      }
    },
    productStyleClasses () {
      return {
        [`${this.prefix}-product`]: true,
        [`${this.prefix}-product--single`]: this.productGroupType === 'single',
        [`${this.prefix}-slider__cell`]: this.productGroupType === 'slider' || this.productGroupType === 'deals',
        [`${this.prefix}-deals-slider__cell`]: this.productGroupType === 'deals'
      }
    },
    unifiedProducts () {
      return this.unifyProducts(this.productList?.products)
    },
    hasTechnicalData () {
      return this.unifiedProducts?.some((product) => !!product.technicalData?.length)
    }
  },
  methods: {
    unifyProducts (products = []) {
      return products.filter(product => this.unifyingSeller[product.productType]).map(product => this[this.unifyingSeller[product.productType]](product[product.productType], product.productType))
    },
    calcSaving (listPrice, sellingPrice) {
      return Math.round(100 / listPrice * (listPrice - sellingPrice))
    },
    formatCurrencyString (cent) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(cent / 100)
    },
    productLabelStyleClasses (product) {
      return {
        [`${this.prefix}-product__label`]: true,
        [`${this.prefix}-product__label--no`]: !product.label
      }
    },
    unifyAmazon (data, type) {
      if (!data.listPrice) {
        this.outOfStockProductExists = true
      }

      return {
        type,
        id: null,
        link: {
          url: data.affiliateUrl,
          target: this.rsConfig.productLinkTarget,
          rel: 'sponsored noopener'
        },
        label: data.label,
        image: data.altImage && data.altImage.imageId
          ? this.createImgSrc({
            image: data.altImage,
            brand: this.brand,
            setName: 'productGroupImage',
            srcSetDefinition: {
              format: 'landscape'
            }
          })
          : data.image && data.image.url ? data.image.url : false,
        roofline: 'Amazon',
        title: data.title,
        showPrime: !!data.isEligibleForPrime || !!data.isPrimeExclusive,
        sellingPrice: (data.lowestNewPrice && data.listPrice) ? Math.min(data.lowestNewPrice, data.listPrice) : data.listPrice ? data.listPrice : false,
        listPrice: data.saving !== 0 ? data.listPrice : false,
        saving: data.saving !== 0 ? this.calcSaving(data.listPrice, data.lowestNewPrice) : false,
        outOfStock: !data.listPrice,
        button: data.dealEndTime ? 'Zum Deal' : !data.listPrice ? 'Verfügbarkeit prüfen' : 'Zum Produkt',
        dealEndTime: data.dealEndTime || null,
        otherPartners: data.otherPartners || null,
        prosAndCons: data.prosAndCons || null,
        technicalData: this.getProductTechnicalData(data),
        editorialStarRating: data.editorialStarRating || null
      }
    },
    unifyManual (data, type) {
      return {
        type,
        id: null,
        link: {
          url: data.url,
          // rsConfig is from configStore and imported by component that uses this mixin
          target: this.rsConfig.productLinkTarget,
          rel: `${data.rel} noopener`
        },
        label: data.label,
        image: data.image
          ? this.createImgSrc({
            image: data.image,
            brand: this.brand,
            setName: 'productGroupImage',
            srcSetDefinition: {
              format: 'landscape'
            }
          })
          : false,
        roofline: data.roofLine,
        title: data.title,
        showPrime: false,
        sellingPrice: data.price,
        listPrice: data.strikePrice > 0 ? data.strikePrice : false,
        saving: data.strikePrice > 0 ? this.calcSaving(data.strikePrice, data.price) : false,
        outOfStock: false,
        button: 'Zum Produkt',
        dealEndTime: data.dealEndTime || null,
        otherPartners: data.otherPartners || null,
        prosAndCons: data.prosAndCons || null,
        technicalData: this.getProductTechnicalData(data),
        editorialStarRating: data.editorialStarRating || null
      }
    },
    unifyAwin (data, type) {
      return {
        type,
        id: null,
        link: {
          url: data.url,
          // rsConfig is from configStore and imported by component that uses this mixin
          target: this.rsConfig.productLinkTarget,
          rel: `${data.rel} noopener`
        },
        label: data.label,
        image: data.image?.imageId
          ? this.createImgSrc({
            image: data.image,
            brand: this.brand,
            setName: 'productGroupImage',
            srcSetDefinition: {
              format: 'landscape'
            }
          })
          : data.imageUrl ? data.imageUrl : false,
        roofline: data.merchantName,
        title: data.title,
        showPrime: false,
        sellingPrice: data.price,
        listPrice: data.oldprice > 0 ? data.oldprice : false,
        saving: data.oldprice > 0 ? this.calcSaving(data.oldprice, data.price) : false,
        outOfStock: false,
        button: !data.price ? 'Verfügbarkeit prüfen' : 'Zum Produkt',
        dealEndTime: data.dealEndTime || null,
        otherPartners: data.otherPartners || null,
        prosAndCons: data.prosAndCons || null,
        technicalData: this.getProductTechnicalData(data),
        editorialStarRating: data.editorialStarRating || null
      }
    },
    hasManualProduct () {
      return this.productGroup.products?.some(({ productType }) => productType === 'manual')
    },
    getProductTechnicalData (product) {
      return product.technicalData?.show && product.technicalData?.items?.length ? product.technicalData.items : null
    }
  }
}
