<template>
  <div class="bx-tech-data__toggle"
       :class="{ 'bx-js-tec-data-hide': isCollapsed }"
       tabindex="0"
       role="button"
       :aria-label="ariaLabel"
       :aria-controls="ariaControls"
       :aria-expanded="isCollapsed"
       data-tc="product-technical-data"
       @click="toggleTechDataTable"
       @keyup.enter="toggleTechDataTable">
    <div class="bx-tech-data__headline">
      <svg class="icon icon-arrow_toggle">
        <use href="/images/icon_sprite.svg#arrow_toggle" />
      </svg>
      <span :class="titleClass">Technische Daten</span>
    </div>
  </div>
  <div :id="ariaControls"
       class="bx-tech-data__table"
       :class="{ 'bx-js-tec-data-hide': isCollapsed }">
    <template v-for="(row, rowIndex) in uniqueKeys"
              :key="rowIndex">
      <div :data-tc="`product-technical-data-${rowIndex}-key`">
        <span>{{ row }}</span>
      </div>
      <div>
        <div v-for="(product, productIndex) in unifiedProducts"
             :key="product.id"
             :data-tc="`product-technical-data-${rowIndex}-${productIndex}-value`">
          {{ getValue(row, product) }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    unifiedProducts: {
      type: Array,
      default: () => []
    },
    titleClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isCollapsed: true
    }
  },
  computed: {
    uniqueKeys () {
      const keys = new Set()
      this.unifiedProducts.forEach(product => {
        product.technicalData?.forEach(item => {
          if (item.key) {
            keys.add(item.key)
          }
        })
      })
      return Array.from(keys)
    },
    ariaLabel () {
      return `Tabelle mit technische Daten zu diesem Produkt/zu diesen Produkten ${this.isCollapsed ? 'öffnen' : 'schliessen'}`
    },
    ariaControls () {
      let titleConcat = ''
      this.unifiedProducts.forEach(product => { titleConcat += product.title })
      return 'bx-aria-controls-id-' + this.hashCode(titleConcat)
    }
  },
  mounted () {

  },
  methods: {
    getValue (key, product) {
      const item = product.technicalData?.find(i => i.key === key)
      return item ? item.value : '-'
    },
    toggleTechDataTable () {
      this.isCollapsed = !this.isCollapsed
    },
    hashCode (str) {
      let hash = 0
      let i
      let chr
      if (str.length) {
        for (i = 0; i < str.length; i++) {
          chr = str.charCodeAt(i)
          hash = (hash << 5) - hash + chr
          hash |= 0 // Konvertiere zu 32-Bit Integer
        }
      }

      return hash
    }
  }
}
</script>
