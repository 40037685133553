<template>
  <main class="asset-container bx-grid--container">
    <ExitIntent v-if="isClient && enableExitIntend" />
    <PrintHeader v-if="isPrintPreview"
                 :brand="brand" />
    <Message v-if="showMessage" />
    <slot name="content-top" />
    <Presenter v-if="metaData?.presenter?.imageId"
               :presenter="metaData.presenter"
               :brand="brand" />
    <AdaNativeHeader v-if="metaData?.nativeHeader"
                     :native-header="metaData.nativeHeader"
                     :brand="brand" />
    <HotGossip v-if="headerData.additionalMenu && !metaData.seeding"
               :additional-menu="headerData.additionalMenu"
               :brand="brand" />
    <Breadcrumb v-if="asset.mainStructurePath && !isRootUrl"
                :breadcrumb="asset.mainStructurePath"
                :page-type="pageMeta.pageType" />
    <Advertorial v-if="metaData && metaData.advertorial && !metaData.hideAdvertorial" />
    <TrackingPixel v-if="metaData && metaData.trackingPixelContent"
                   :tracking-pixel="metaData.trackingPixelContent" />
    <TrackingPixel v-if="metaData && metaData.respondiTrackingPixelContent"
                   :tracking-pixel="metaData.respondiTrackingPixelContent" />

    <Roofline v-if="asset.roofline && asset.roofline.length > 0"
              :text="asset.roofline" />
    <Headline v-if="asset.headline && asset.headline.length > 0 && !isRootUrl"
              :meta-data="metaData"
              :text="asset.headline" />
    <PrintUsage v-if="metaData.printUsage"
                :print-usage="metaData.printUsage" />
    <JobTitle v-if="asset.jobTitle && asset.jobTitle.length > 0"
              :text="asset.jobTitle" />
    <Author v-if="showAuthors"
            :composed-authors="combineAuthors"
            :settings="{
              brand,
              displayDate: metaData.hideDisplayDate ? false : asset.displayDate
            }" />
    <AdaPagestripMain v-if="metaData?.pagestripMainId"
                      :pagestrip-id="metaData.pagestripMainId" />

    <SeriesName v-if="asset.series && pageMeta.isSeriesMember"
                :series-master="asset.series[0]"
                :asset-id="pageMeta.id" />
    <IntroductionText v-if="asset.introductionText && asset.introductionText.length > 0"
                      :html="asset.introductionText" />
    <AffiliateDisclaimer v-if="metaData.showAffiliateDisclaimer" />
    <SocialShare v-if="pageMeta?.pageType === 'article' && (!metaData.advertorial || (metaData.advertorial && metaData.hideAdvertorial)) && rsConfig?.socialSettings?.socialShare?.enableTopPosition"
                 :page-meta="pageMeta"
                 :headline="asset.headline" />
    <SeriesNavigationTop v-if="asset.series"
                         :series="asset.series"
                         :page-meta="pageMeta" />
    <section :class="{ 'bx-aside-wrapper': asideEnabled }">
      <div>
        <Paragraphs :paragraphs="asset.paragraphs"
                    :meta-data="metaData"
                    :page-meta="pageMeta"
                    :brand="brand"
                    :page-type="pageMeta.pageType"
                    :include-depth="0">
          <template v-for="(_, slot) of $slots"
                    #[slot]="scope">
            <slot :name="slot"
                  v-bind="scope" />
          </template>
        </Paragraphs>
        <SeriesNavigationBottom v-if="asset.series && pageMeta"
                                :series="asset.series"
                                :page-meta="pageMeta" />

        <Tags v-if="pageMeta.pageType === 'article' && assetTags.length > 0"
              :tags="assetTags" />

        <PagePagination v-if="pageMeta.numPages > 1"
                        :page-meta="pageMeta" />
        <ParagraphTypeHtml v-if="asset.seoText"
                           :meta-data="metaData"
                           :html="asset.seoText" />
      </div>
      <PageAside v-if="asideEnabled"
                 :brand="brand" />
    </section>
    <AdaNativeCrossbar v-if="isClient && enableNcbInDynamicMode"
                       position="top" />
    <PrintFooter v-if="isPrintPreview" />
  </main>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useNotifyStore } from '../stores/notify'
import { useSourcepointStore } from '../stores/sourcepoint'
import { useSessionStore } from '../stores/session'
import { useConfigStore } from '../stores/config'
import { usePageStore } from '../stores/page'
import { useAdaStore } from '../stores/ada'
import clientOnly from '../mixins/client-only'
import adaSlotLoader from '../mixins/ada-slot-loader'
import adaDynamicSlotLoader from '../mixins/ada-dynamic-slot-loader'
import deviceDetection from '../mixins/device-detection'
import urlHelper from '../mixins/url-helper.js'
import adaAdSizePredictionCss from '../mixins/ada-ad-size-prediction-css'
import ExitIntent from './ad-slots/ExitIntent.vue'
import PrintFooter from './content-footer/PrintFooter.vue'
import SocialShare from './content-footer/SocialShare.vue'
import Advertorial from './content-header/Advertorial.vue'
import AffiliateDisclaimer from './content-header/AffiliateDisclaimer.vue'
import Author from './content-header/Author.vue'
import Breadcrumb from './content-header/Breadcrumb.vue'
import Headline from './content-header/Headline.vue'
import IntroductionText from './content-header/IntroductionText.vue'
import JobTitle from './content-header/JobTitle.vue'
import Message from './content-header/Message.vue'
import PrintHeader from './content-header/PrintHeader.vue'
import PrintUsage from './content-header/PrintUsage.vue'
import Roofline from './content-header/Roofline.vue'
import HotGossip from './menu/HotGossip.vue'
import PageAside from './PageAside.vue'
import PagePagination from './PagePagination.vue'
import ParagraphTypeHtml from './paragraph-types/ParagraphTypeHtml.vue'
import Paragraphs from './Paragraphs.vue'
import SeriesName from './series/seriesName.vue'
import SeriesNavigationBottom from './series/seriesNavigationBottom.vue'
import SeriesNavigationTop from './series/seriesNavigationTop.vue'
import Tags from './shared/Tags.vue'
import TrackingPixel from './tracking/TrackingPixel.vue'
import Presenter from './shared/Presenter.vue'
import AdaNativeHeader from './ad-slots/AdaNativeHeader.vue'
import AdaPagestripMain from './ad-slots/AdaPagestripMain.vue'
import AdaNativeCrossbar from './ad-slots/AdaNativeCrossbar.vue'

export default {
  components: {
    Breadcrumb,
    SocialShare,
    Headline,
    JobTitle,
    Roofline,
    IntroductionText,
    AffiliateDisclaimer,
    Author,
    PrintUsage,
    Paragraphs,
    ParagraphTypeHtml,
    Tags,
    Advertorial,
    TrackingPixel,
    PageAside,
    PagePagination,
    SeriesName,
    SeriesNavigationTop,
    SeriesNavigationBottom,
    PrintHeader,
    PrintFooter,
    HotGossip,
    Message,
    ExitIntent,
    Presenter,
    AdaNativeHeader,
    AdaPagestripMain,
    AdaNativeCrossbar
  },
  mixins: [adaSlotLoader, adaDynamicSlotLoader, deviceDetection, urlHelper, clientOnly, adaAdSizePredictionCss],
  props: {
    // The asset object holding all page data from the CAPI/Composer
    asset: {
      type: Object,
      default: () => { return {} }
    },
    brand: {
      type: String,
      default: ''
    },
    pageMeta: {
      type: Object,
      default: () => {
        return {}
      }
    },
    headerData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    metaData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      assetTags: [],
      showMessage: false
    }
  },
  head () {
    const metaInfo = {
      script: [],
      meta: [],
      link: [],
      style: []
    }
    if (!import.meta.env.SSR) {
      const hashCode = s => (s.split('').reduce((a, b) => (a = ((a << 5) - a) + b.charCodeAt(0)) & a, 0) >>> 0).toString(16)
      const toolkitAutoLoad = document.cookie.split('; ').find(row => row.startsWith('toolkitAutoLoad='))?.split('=')[1] || false
      const src = `/js/${toolkitAutoLoad}.js`
      if (hashCode(src) === '987cdce9' && !document.querySelectorAll(`script[src="${src}"]`).length) {
        metaInfo.script.push({
          'data-vmid': 'toolkitAutoLoad',
          src,
          async: true
        })
      }
      if (this.deviceType === 'tablet' && this.screenOrientation === 'portrait') {
        document.querySelector('[data-viewport="main"]')?.remove()
        metaInfo.meta.push({
          'data-vmid': 'bx-viewport',
          name: 'viewport',
          content: `width=1165, user-scalable=yes, initial-scale=${window.width < window.screen.width
            ? window.width / 1165
            : window.screen.width / 1165}`
        })
        this.setViewportMetaModified(true)
      }
    }
    metaInfo.style.push(this.getAdSizePredictionStyleData())
    return metaInfo
  },
  computed: {
    ...mapState(useSourcepointStore, ['consentsExist', 'consentConfig']),
    ...mapState(useSessionStore, ['hasPurSub']),
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(usePageStore, ['pageData', 'isPrintPreview', 'hasPreviewToken']),
    ...mapState(useAdaStore, ['loadPreviewBundle', 'blockAds', 'adSizePrediction', 'dynamicAdSlotsEnabled', 'asideEnabled']),
    hasPreviewToken () {
      return !import.meta.env.SSR && /[?&]previewToken=/.test(window.location.search)
    },
    isRootUrl () {
      return this.pageData.pageMeta.url === '/'
    },
    showAuthors () {
      return (this.pageMeta.pageType === 'article' && !this.metaData.seeding) || (this.pageMeta.pageType === 'structureterm' && this.asset.author)
    },
    combineAuthors () {
      const authors = []
      if (this.asset.author) {
        authors.push(this.asset.author)
      }
      if (this.asset.additionalAuthors?.length) {
        authors.push(...this.asset.additionalAuthors)
      }
      return authors
    },
    enableNcbInDynamicMode () {
      return !!(
        !import.meta.env.SSR &&
        !this.pageData.metaData.disallowNativeTeasers &&
        !this.metaData.advertorial &&
        !this.metaData?.disallowNativeTeasers &&
        (this.pageMeta.pageType === 'article' || this.metaData?.behaveLikeArticle) &&
        this.dynamicAdSlotsEnabled
      )
    },
    enableExitIntend () {
      return !!(
        !import.meta.env.SSR &&
        this.rsConfig.adSlotUsage.exitIntentOverlay.enabled &&
        this.viewportWidth &&
        !this.hasMobileAds &&
        !this.isTablet &&
        !this.hasPreviewToken &&
        !this.isPrintPreview &&
        this.pageMeta?.articleType !== 'recipe' &&
        !this.metaData.advertorial &&
        !this.consentConfig.requestPathWhitelist.includes(this.pageMeta?.url)
      )
    },
    // For cases where the molten bundle should be loaded, but the ads shouldn't be initialised
    shouldLoadMoltenBundle () {
      return !this.hasPurSub && !process.env.AD_FREE_BRANDS.includes(this.brand)
    }
  },
  watch: {
    consentsExist (newValue) {
      if (this.shouldLoadMoltenBundle && newValue) {
        this.appendMoltenBundleTopScript()
        if (!this.blockAds) {
          this.initAdaBasedOnPlacementLogic()
        }
        this.appendMoltenBundleBottomScript()
      }
    }
  },
  beforeMount () {
    if (this.shouldLoadMoltenBundle && this.consentsExist) {
      this.appendMoltenBundleTopScript()
      if (!this.blockAds) {
        this.$nextTick(() => {
          this.initAdaBasedOnPlacementLogic()
        })
      }
    }
  },
  mounted () {
    if (this.shouldLoadMoltenBundle && this.consentsExist) {
      this.appendMoltenBundleBottomScript()
    }
    // Show PrivacyManager with revoke button if not PUR and only if consents and URL Param exist
    const showPrivacyManagerModal = new URL(window.location).searchParams.get('showprivacymanager')
    if (showPrivacyManagerModal && !this.hasPurSub && this.consentsExist) {
      this.openPrivacyManager()
    }

    this.assignAssetTags()
    this.checkShowMessage()
    this.checkShowNotification()
    window.addEventListener('keydown', this.handleFirstTab)
  },
  methods: {
    ...mapActions(useNotifyStore, ['showNotification']),
    ...mapActions(usePageStore, ['setViewportMetaModified']),
    ...mapActions(useAdaStore, ['setAsyncContentMode']),
    async openPrivacyManager () {
      // sometimes sourcepoint is not loaded or the window.openPrivacyManager is not defined on component mount, so we retry here
      // waiting for document.readyState === 'complete' is not enough, because the sourcepoint script is loaded async and theres no event when its loaded that we can listen to
      let retryCount = 0
      while (retryCount < 25) {
        // apparently waiting for window._sp_ is not enough, the function gets attached to the _sp_ object during runtime. big fuck
        if (typeof window._sp_?.loadPrivacyManagerModal === 'function' && typeof window.openPrivacyManager === 'function') {
          window.openPrivacyManager()
          break
        } else {
          retryCount++
          // wait for 200ms before next attempt
          await new Promise(resolve => setTimeout(resolve, 200))
        }
      }
    },
    initAdaBasedOnPlacementLogic () {
      if (this.dynamicAdSlotsEnabled) {
        // // disabled asyncContent ad loading solution for further development
        // this.checkIfAsyncContentPage()
        this.initAdaDynamicSlotLoader()
      } else {
        this.initAdaSlotLoader()
      }
    },
    // checkIfAsyncContentPage () {
    //   // for pages with async content (i.e. cookbook and search) we have to handle dynamic ad placement in a special way.
    //   // At this point we detect the supported pages by identifying the type and name from the first component
    //   const relatedVueComponentNames = ['ParagraphTypeLeckerCookbook', 'ParagraphTypeRecipeSearch']
    //   if (this.asset?.paragraphs[0]?.paragraphType === 'vueComponent' && relatedVueComponentNames.includes(this.asset?.paragraphs[0]?.vueComponent.componentName)) {
    //     this.setAsyncContentMode(true)
    //   }
    // },
    moltenBundleScriptElement (position) {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = 'true'
      const isSelbst = this.brand === 'selbst' // TODO: remove after launch: https://bauerxcel.atlassian.net/browse/NXT-6509
      s.src = this.loadPreviewBundle
        ? `https://adctrl.emsmobile.de/molten/preview/${isSelbst ? 'selbst_next' : this.brand}/MoltenBundle.${position}.js`
        : `${this.rsConfig.cdnUri}/moltenBundles/${this.brand}/MoltenBundle.${position}.js`
      s.dataset.vmid = `molten-bundle-${position}`
      return s
    },
    appendMoltenBundleTopScript () {
      document.head.appendChild(this.moltenBundleScriptElement('top'))
    },
    appendMoltenBundleBottomScript () {
      document.body.appendChild(this.moltenBundleScriptElement('bottom'))
    },
    assignAssetTags () {
      let tags = []
      if (this.asset.mainStructurePath) {
        tags = [...this.asset.mainStructurePath]
      }
      if (this.asset.additionalStructures) {
        tags = [...tags, ...this.asset.additionalStructures]
      }
      this.assetTags = tags.filter((tag, index, self) => self.findIndex(e => e.name === tag.name) === index)
        .filter(tag => tag.url !== '/')
        .filter(tag => !this.rsConfig.tagListExcludeTags.find(url => url === tag.url))
    },
    checkShowMessage () {
      this.showMessage = !!(!import.meta.env.SSR && window.location.search.toLowerCase().includes('utm_campaign=doi-success'))
    },
    checkShowNotification () {
      const showNotification = this.getQueryParams()?.notify
      if (showNotification) {
        const params = new URLSearchParams(window.location.search)
        params.delete('notify')
        const cleanUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}` + ([...params].length ? `?${params}` : '')
        window.history.pushState({ path: cleanUrl }, '', cleanUrl)
      }

      switch (showNotification) {
        case 'activation-success':
          this.showNotification({
            type: 'info',
            title: 'Aktivierung abgeschlossen',
            message: `Herzlich willkommen bei ${this.rsConfig.actualBrandName}. Du bist jetzt direkt eingeloggt und kannst starten.`
          })
          break
        case 'login-success':
          this.showNotification({
            title: 'Anmeldung erfolgreich',
            message: 'Du bist jetzt eingeloggt.'
          })
          break
        case 'change-password-success':
          this.showNotification({
            title: 'Speichern erfolgreich',
            message: 'Deine neues Passwort wurde erfolgreich gespeichert.'
          })
          break
        case 'logout-success':
          this.showNotification({
            title: 'Erfolgreich abgemeldet',
            message: 'Du hast dich erfolgreich abgemeldet.'
          })
          break
        case 'account-deleted':
          this.showNotification({
            title: 'Löschen erfolgreich',
            message: 'Dein Konto wurde erfolgreich gelöscht.'
          })
          break
      }
    },
    handleFirstTab (e) {
      if (e.keyCode === 9) { // watch out for tab key to identify keyboard users
        document.body.classList.add('bx-js-user-is-tabbing')
        window.removeEventListener('keydown', this.handleFirstTab)
        window.addEventListener('mousedown', this.handleMouseDownOnce)
      }
    },
    handleMouseDownOnce () {
      document.body.classList.remove('bx-js-user-is-tabbing')
      window.removeEventListener('mousedown', this.handleMouseDownOnce)
      window.addEventListener('keydown', this.handleFirstTab)
    }
  }
}
</script>
